import {
  TrackByChassisNoRes,
  TrackByChassisNoApiRes,
  TrackByIdReq,
  TrackByIdRes,
  TrackByIdApiRes,
  VerifyCertificateReq,
  VerifyCertificateRes,
  VerifyCertificateApiRes,
} from './types';

export const trackByChassisNoRes = (response: TrackByChassisNoApiRes): TrackByChassisNoRes => {
  return {
    result: response.data.map((row) => ({
      uuid: row.uuid,
      corNo: row.cor_no,
      inspectionDate: row.inspection_date,
      make: row.make,
      model: row.model,
      chassisNo: row.chassis_no,
      status: row.status,
    })),
  };
};

export const trackByIdRes = (response: TrackByIdApiRes, args: TrackByIdReq) => {
  response.scope = args.params.destination;

  const r: any = response.data;
  const data: any = {
    scope: args.params.destination,
    result: {
      // @ts-ignore
      status: r.ncr === true && r.ncr_closed === false ? 'NCR' : 'Pass',
    },
  };

  // @ts-ignore
  if (response.scope === 'jamaica' || response.scope === 'guyana') {
    data.result = {
      ...data.result,
      corNo: response.data.cor_no,
      countrySupply: response.data.country_supply,
      inspectionBranchName: response.data.inspection_branch_name,
      inspectionDate: response.data.inspection_date,

      make: response.data.make,
      model: response.data.model,
      chassisNo: response.data.chassis_no,
      yearManufacture: response.data.year_of_manufacture.toString(),
      firstRegistration: `${response.data.first_registration_year}-${response.data.first_registration_month.toString().padStart(2, '0')}`,
      originalColor: response.data.original_color,
      engineNo: response.data.engine_no,
      engineCapacity: response.data.engine_capacity,
      engineCapacityUnit: response.data.engine_capacity_unit,
      odometerReading: response.data.odometer_reading,
      odometerReadingUnit: response.data.odometer_reading_unit,

      remarks: response.data.remarks,
    };
  }

  if (response.scope === 'zambia') {
    data.result = {
      ...data.result,
      corNo: response.data.cor_no,
      countrySupply: response.data.country_supply,
      inspectionBranchName: response.data.inspection_branch_name,
      inspectionDate: response.data.inspection_date,

      make: response.data.make,
      model: response.data.model,
      chassisNo: response.data.chassis_no,
      yearManufacture: response.data.year_of_manufacture.toString(),
      firstRegistration: `${response.data.first_registration_year}-${response.data.first_registration_month.toString().padStart(2, '0')}`,
      engineNo: response.data.engine_no,
      engineCapacity: response.data.engine_capacity,
      engineCapacityUnit: response.data.engine_capacity_unit,
      odometerReading: response.data.odometer_reading,
      odometerReadingUnit: response.data.odometer_reading_unit,

      remarks: response.data.remarks,
    };
  }

  if (response.scope === 'odometer') {
    data.result = {
      ...data.result,
      corNo: response.data.cor_no,
      registrationNo: response.data.registration_no,
      inspectionDate: response.data.inspection_date,

      make: response.data.make,
      model: response.data.model,
      chassisNo: response.data.chassis_no,
      odometerReading: response.data.odometer_reading,
      odometerReadingUnit: response.data.odometer_reading_unit,

      remarks: response.data.remarks,
    };
  }

  // @ts-ignore
  if (response.scope === 'uganda') {
    // @ts-ignore
    const _data = response.data as any;

    data.result = {
      ...data.result,
      corNo: _data.cor_no,
      countrySupply: _data.country_supply,
      inspectionBranchName: _data.inspection_branch_name,
      inspectionDate: _data.inspection_date,

      make: _data.make,
      model: _data.model,
      chassisNo: _data.chassis_no,
      yearManufacture: _data.year_of_manufacture.toString(),
      firstRegistration: `${_data.first_registration_year}-${_data.first_registration_month.toString().padStart(2, '0')}`,
      // @ts-ignore
      engineNo: response.data.engine_model,
      engineCapacity: _data.engine_capacity,
      engineCapacityUnit: _data.engine_capacity_unit,
      odometerReading: _data.odometer_reading,
      odometerReadingUnit: _data.odometer_reading_unit,

      remarks: _data.remarks,
    };
  }

  return data as TrackByIdRes;
};

export const verifyCertificateRes = (response: VerifyCertificateApiRes, args: VerifyCertificateReq) => {
  const data: Partial<VerifyCertificateRes> = {
    scope: undefined,
    // @ts-ignore
    result: {
      certificateUrl: response.data.certificate_url,
    },
  };

  if (args.params.destination === 'jamaica') {
    data.scope = args.params.destination;
    data.result = {
      ...trackByIdRes(response, args).result,
      certificateUrl: response.data.certificate_url,
    } as VerifyCertificateRes['result'];
  }

  return data as VerifyCertificateRes;
};
